import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { APP_BASE_HREF, CommonModule } from "@angular/common";

/** components */
import { DashboardComponent, LoginWrapperComponent, AuthGuard, environment } from "@energy-city/components";
import { SidenavigationComponent } from "@energy-city/components/sidenavigation";

/** guards */
import { EneModalService } from "@energy-city/ui/modal";

const appRoutes: Routes = [
  { path: "login", component: LoginWrapperComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "co2/:panel", component: SidenavigationComponent, canActivate: [AuthGuard], data: { module: "co2" } },
  { path: "", redirectTo: "co2/cockpit", pathMatch: "full" }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      relativeLinkResolution: "legacy"
    })
  ],
  exports: [RouterModule],
  providers: [EneModalService, [{ provide: APP_BASE_HREF, useValue: environment.relativeBaseUrl }]]
})
export class AppRoutingModule {}
