import { Injectable } from "@angular/core";
import { IPanelEvent, EneSidenavComponent, PanelEvent } from "@energy-city/ui/sidenav";
import * as fromRoot from "@energy-city/components";
import { Store } from "@ngrx/store";
import { AppActionType, ModulesService } from "@energy-city/components";
import { ofType } from "@ngrx/effects";

@Injectable()
export class SidenavigationService {
  private panelsMap;

  constructor(private store: Store<fromRoot.IApplicationState>, private moduleService: ModulesService) {
    this.initPanelArray();
  }

  public initPanelArray(): void {
    this.store
      .select(fromRoot.GetAppState)
      .pipe(ofType(AppActionType.GET_CLIENT_CONFIG_SUCCESS))
      .subscribe(() => {
        const pannelArray = this.moduleService.getActiveModule().panels;
        this.panelsMap = Object.keys(pannelArray).reduce(
          (acc, item) => ({
            ...acc,
            [item]: pannelArray[item]["groupId"] || 0
          }),
          {}
        );
      });
  }

  public closePanels(sidenav: EneSidenavComponent, panelEvent: IPanelEvent) {
    if (panelEvent.event === PanelEvent.VISIBLE && panelEvent.state && panelEvent.name !== "timeline") {
      const gNumber = this.panelsMap[panelEvent.name];
      Object.keys(this.panelsMap)
        .filter((key) => this.panelsMap[key] !== gNumber && sidenav.getPanel(key).visible)
        .forEach((name) => {
          if (name !== "timeline") {
            sidenav.setVisible(name, false);
          }
        });
    }
  }
}
