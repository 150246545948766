import { EnvironmentType } from "../app/core/enum/environment.enum";
import { IEnvironment } from "../app/core/interfaces/environment.interface";
import { LoginType } from "../app/modules/authentication/core/global.types";

// TODO: can be removed after full migration to DC2, as soon as the "old" paths are not used anymore.
function ifDefined(variable: string, fallback: string): string {
  return variable.startsWith("${") ? fallback : variable;
}

function isFeatureSet(feature: string): boolean {
  return `${feature}`.toLowerCase() === "true"; // prevent terser from over-optimizing and removing the string.
}

export const environment: IEnvironment = {
  image_sha: "${APP_IMAGE_SHA}",
  production: true,
  apiBaseUrl: "${APP_SERVICES_HOST}",
  configServiceApi: ifDefined("${SVC_EXTRA_MODULE_00000_CONFIG_BASEURL}", "${APP_CONFIG_HOST}/config-service/v1/api"),
  infrastructure: {
    regionApi: ifDefined(
      "${SVC_EXTRA_MODULE_00000_INFRASTRUCTURE_REGION_BASEURL}",
      "<%API_BASEURL%>/infrastructure/region/v1/api"
    ),
    gridApi: "not used"
  },
  emission: {
    calculationApi: "not used"
  },
  regionPropertyApi: ifDefined("${SVC_EXTRA_MODULE_00000_API_BASEURL}", "<%API_BASEURL%>/region-property"),
  translateServiceApi: ["assets/i18n/", ".json"],
  greenitedDeepThought: "not used",
  networkServiceApi: "not used",
  co2StaticValuesService: "<%API_BASEURL%>/co2-static-values/v1/api",
  co2DummyDataService: "<%API_BASEURL%>/co2-dummy-service/v1/api",
  emailEdgeService: "<%API_BASEURL%>/communication-email/v0",
  kcRightsService: "<%API_BASEURL%>/security/kc-rights/v1/api",
  dataLayerClasses: {},
  detailInformationClasses: {},
  selected_year: null,
  timeperiodPossibleYears: null,
  environment: "${APP_ENVIRONMENT}" as EnvironmentType,
  searchApiKey: "${APP_SEARCH_API_KEY}",
  loginConfig: {
    publicLogin: JSON.parse("${APP_LOGIN_PUBLIC}"),
    supportsSSO: JSON.parse("${APP_LOGIN_SUPPORTS_SSO}"),
    serviceUrl: "${APP_LOGIN_SERVICE_URL}",
    refreshUrl: "${APP_LOGIN_REFRESH_URL}",
    loginType: "${APP_LOGIN_TYPE}" as LoginType,
    realm: "${APP_LOGIN_REALM}",
    clientId: "${APP_LOGIN_CLIENT_ID}"
  },
  simulation: ifDefined(
    "${SVC_EXTRA_MODULE_00000_SIMULATION_BASEURL}",
    "<%API_BASEURL%>/simulation/calculation/v0/api"
  ),
  configState: ifDefined("${SVC_EXTRA_MODULE_00000_STATE_BASEURL}", "<%API_BASEURL%>/config/state/v0/api"),
  baseUrl: ifDefined("${SVC_SELF_BASEURL}", "${APP_BASE_URL}"),
  relativeBaseUrl: "${APP_RELATIVE_BASE_URL}",

  energyCityFeatureFlags: {
    factorManagement: !isFeatureSet("${APP_FEATURE_DISABLE_USER_FACTORS}"),
    hideMainIssuerCharts: isFeatureSet("${APP_FEATURE_HIDE_MAIN_ISSUER_CHARTS}")
  }
};
