import { AfterContentInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  EneSidenavComponent,
  EneSidenavService,
  EneSidepanelComponent,
  IPanelEvent,
  PanelEvent
} from "@energy-city/ui/sidenav";

// ngrx
import { ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";

// states
import * as fromRoot from "@energy-city/components";
import {
  AppActionType,
  AuthenticationService,
  GetClientConfigSuccess,
  IRegionIdentifier,
  ModulesService,
  PopupService,
  ResetActionType,
  ResetUpdate,
  SideNavigationActionType,
  SideNavigationEvent,
  SideNavigationInit,
  UtilService,
  environment
} from "@energy-city/components";

// Services
import { subscribeToEvent } from "@enersis/gp-components/events";
import { RegionTypes } from "../../core/enum/region-types.enum";
import { SidenavigationService } from "./sidenavigation.action.service";

@Component({
  selector: "app-sidenavigation",
  templateUrl: "./sidenavigation.component.html",
  styleUrls: ["./sidenavigation.component.scss"],
  providers: [SidenavigationService]
})
export class SidenavigationComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild("sidenav", { static: true }) public sidenav: EneSidenavComponent;
  @ViewChild("benchmarking", { static: false }) public benchmarking: EneSidepanelComponent;

  public hasGasExtension: boolean;
  // Make sure to use the same names in Reset Config

  private destroy$ = new Subject();
  public panelsWidth: number;

  public clientConfig: any = {};

  public userGetsNetwork: boolean;
  public config: any = {};
  public benchmarkingDisable$: Observable<boolean>;

  constructor(
    public store: Store<fromRoot.IApplicationState>,
    private authService: AuthenticationService,
    public moduleService: ModulesService,
    private sidenavService: EneSidenavService,
    private side: SidenavigationService,
    private utilService: UtilService,
    protected popupService: PopupService
  ) {
    subscribeToEvent("glossary: open", () => {
      this.popupService.openGlossaryAtTab(2);
    });
  }

  public ngOnInit() {
    const resizeObserver = new ResizeObserver((entries) => {
      const customEvent = new CustomEvent("panelContainerWidthChanged", {
        detail: entries[0].contentRect.width,
        bubbles: true
      });
      entries[0].target.dispatchEvent(customEvent);
    });
    resizeObserver.observe(document.getElementById("sidenavigation"));

    this.userGetsNetwork = this.authService.roles.includes("network");

    this.sidenavService.onPanelChange$.subscribe((res) => {
      this.store.dispatch(new SideNavigationEvent(res));
      this.side.closePanels(this.sidenav, res);
    });
    this.sidenavService.onPanelInit$.subscribe((res) => {
      this.store.dispatch(new SideNavigationInit(res));
    });

    this.store
      .pipe(select(fromRoot.GetAppState), ofType(AppActionType.GET_CLIENT_CONFIG_SUCCESS), takeUntil(this.destroy$))
      .subscribe(({ payload }: GetClientConfigSuccess) => {
        this.clientConfig = this.moduleService.getActiveModule();
        this.sidenavService.sidenav = this.sidenav;
      });

    this.store
      .pipe(select(fromRoot.GetResetState), ofType(ResetActionType.RESET_UPDATE), takeUntil(this.destroy$))
      .subscribe((res: ResetUpdate) => {
        res.payload.id.sidenavigation.panel.forEach((panel) => {
          if (panel.hasOwnProperty("visible")) {
            this[panel.name].setVisible(panel.visible);
          }
          if (panel.hasOwnProperty("collapse")) {
            this[panel.name].setCollapse(panel.collapse);
          }
          if (panel.hasOwnProperty("enlarge")) {
            this[panel.name].setEnlarge(panel.enlarge);
          }
        });
      });

    this.store
      .pipe(
        select(fromRoot.GetSideNavigationState),
        ofType(SideNavigationActionType.CHANGE_PANEL_STATE),
        takeUntil(this.destroy$)
      )
      .subscribe((res: any) => {
        const payload: IPanelEvent = res.payload;
        switch (payload.event) {
          case PanelEvent.VISIBLE:
            this.sidenav.setVisible(payload.name, payload.state);
            break;
          case PanelEvent.COLLAPSED:
            this.sidenav.setCollapse(payload.name, payload.state);
            break;
          case PanelEvent.ENLARGED:
            this.sidenav.setEnlarge(payload.name, payload.state);
            break;
          case PanelEvent.DISABLED:
            this.sidenav.setDisabled(payload.name, payload.state);
            break;
        }
      });

    this.benchmarkingDisable$ = this.utilService.regionIdentifier$.pipe(
      map(({ regionType }: IRegionIdentifier) => regionType === RegionTypes.UNION),
      tap((value) => (value ? this.benchmarking?.setVisible(false) : null))
    );
  }

  ngAfterContentInit() {
    this.startTracking(this.authService.tokenParsed);
  }

  @HostListener("panelContainerWidthChanged", ["$event"])
  onPanelContainerWidthChanged(event) {
    this.panelsWidth = Number(event.detail);
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private async startTracking(tokenParsed: any) {
    if (tokenParsed?.enableTracking) {
      // A global variable that will be used by the Matomo Tag Manager
      window["_tenant_infos"] = {
        tenant: "tenant-0018", // hard-coded, because all other tenants are using gaia co2balance. Hansewerk is the only tenant using the standalone application.
        country: "de",
        environment: environment.environment
      };

      this.setAdditionalTrackingData(tokenParsed);
      // importing the tracking module will automatically start the tracking
      await import("@enersis/gp-components/tracking");
      this.trackSidenav("klimanavi");
    }
  }

  /**
   * Additional data can be pushed into the so-called "data-layer" of Matomo.
   * This data will not be automatically sent to matomo, but it can be read and
   * transformed by the Matomo Tag Manager. The Matomo Tag Manager can be
   * configured to read from the data-layer and send the found data to matomo as
   * a "Custom Dimension". (Customization of the Matomo Tag Manager is done
   * directly in https://climateintelligence.matomo.cloud/, not in the code.)
   */
  private setAdditionalTrackingData(tokenParsed: any) {
    const _mtm = (window["_mtm"] = window["_mtm"] || []);
    _mtm.push({ "login-level": tokenParsed.mainRegion?.level });
  }

  private trackSidenav(moduleName: string) {
    const panelStartTimes: Record<string, number> = {};

    const trackEvent = (options: { category: string; action: string; name?: string; value?: number }) => {
      window["_paq"]?.push(["trackEvent", options.category, options.action, options.name, options.value]);
    };

    const trackPanelDuration = (panelName: string) => {
      const startTime = panelStartTimes[panelName];
      if (startTime) {
        const duration = (Date.now() - startTime) / 1000;
        //console.log("Tracking panel", panelName, "duration", duration);
        trackEvent({
          category: "exposure",
          action: moduleName,
          name: panelName,
          value: duration
        });
      }
      delete panelStartTimes[panelName];
    };

    const stopCounting = () => {
      for (const panelName in panelStartTimes) {
        //console.log(`STOP counting ${panelName} at ${new Date().toTimeString()}`, panelName);
        trackPanelDuration(panelName);
      }
    };

    const startCounting = () => {
      this.sidenav
        .getOpenPanelNames()
        .filter((name) => name !== "timeline")
        .forEach((panelName) => {
          //console.log(`start counting ${panelName} at ${new Date().toTimeString()}`, panelName);
          panelStartTimes[panelName] = Date.now();
        });
    };

    startCounting();

    this.sidenavService.onPanelChange$.subscribe((payload) => {
      if (payload.name === "timeline") return; // not interested in timeline
      switch (payload.state) {
        case true:
          panelStartTimes[payload.name] = Date.now();
          break;
        case false:
          trackPanelDuration(payload.name);
          break;
      }
    });

    const visibilityHandler = () => {
      if (document.hidden) {
        stopCounting();
      } else {
        startCounting();
      }
    };

    const unloadHandler = () => {
      stopCounting();
    };

    document.addEventListener("visibilitychange", visibilityHandler);
    document.addEventListener("beforeunload", unloadHandler);
  }
}
