/** angular and third party imports */
import { CommonModule, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeDeCH from "@angular/common/locales/de-CH";
import localeFr from "@angular/common/locales/fr";
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NguCarouselModule } from "@ngu/carousel";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular-ivy";
// en-US is imported as standard
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeDeCH);

/** ngrx */
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import {
  AppDqiModule,
  AppEffects,
  AuthGuard,
  AuthenticationModule,
  BenchmarkEffects,
  BenchmarkingModule,
  ChartBenchmarkingEffects,
  ChartCo2EmissionSectorsEffects,
  ChartEnergyEfficiencyComponent,
  Co2CockpitEffects,
  Co2CockpitModule,
  Co2SavingEffects,
  Co2SavingForAllYearsEffects,
  CommentModule,
  ConsumptionEffects,
  ContactEffects,
  ContactFormModule,
  ContactsModule,
  DashboardComponent,
  DataPanelModule,
  DatabaseChartEffects,
  DeprecatedMaptopicsModule,
  DeprecatedTimelineModule,
  DeprecatedTimeperiodModule,
  DirectivesModule,
  DistrictEffects,
  DownloadReportComponent,
  DqiTableModule,
  EMobilityEffects,
  EneEditModule,
  EnergymixSelectComponent,
  FooterModule,
  GasCockpitKpiEffects,
  GasNetworkEffects,
  GetAccountingMethodsConfigEffects,
  HeaderModule,
  HeatpumpEffects,
  IEnvironment,
  InfoPanelModule,
  IntroductionModalModule,
  LocalEnergyMixEffects,
  MainPolluterEffects,
  MeasuresEffects,
  MeasuresModule,
  NetworkEffects,
  PipesModule,
  PopUpModule,
  PopupService,
  ProgressBarModule,
  PutAccountingMethodsConfigEffects,
  ReportsPanelModule,
  RouterEffects,
  SearchboxModule,
  SetsEffects,
  SimulationEffects,
  SimulationModule,
  TokenInterceptor,
  TourEffects,
  assetUrl,
  environment,
  reducerProvider,
  reducerToken
} from "@energy-city/components";
import { SidenavigationComponent } from "@energy-city/components/sidenavigation";

/** modules */
import { EneTooltipModule } from "@energy-city/ui/tooltip";
import { AppRoutingModule } from "./app-routing.module";

/* ENE UI MODULES  */
import { EneAccordeonModule } from "@energy-city/ui/accordeon";
import { EneChartModule } from "@energy-city/ui/chart";
import { EneFilterModule } from "@energy-city/ui/filter";
import { EneFormsModule } from "@energy-city/ui/forms";
import { EneHeaderModule } from "@energy-city/ui/header";
import { EneUiService } from "@energy-city/ui/helper";
import { EneIconModule } from "@energy-city/ui/icon";
import { EneKpisModule, EneKpisService } from "@energy-city/ui/kpis";
import { EneKpisV2Module } from "@energy-city/ui/kpis-v2";
import { EneModalModule, EneModalService } from "@energy-city/ui/modal";
import { EneFormatUnitPipe, EneUiPipesModule } from "@energy-city/ui/pipes";
import { EnePopperModule } from "@energy-city/ui/popper";
import { EneSidenavModule } from "@energy-city/ui/sidenav";
import { EneSnackbarModule } from "@energy-city/ui/snackbar";
import { EneTabsModule } from "@energy-city/ui/tabs";
import { EneSearchModule } from "@enersis/search";

import { CdkTableModule } from "@angular/cdk/table";
/** components */
import { AppComponent } from "./app.component";

import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ACTIVE_ACCOUNTING_SERVICE_TOKEN, ActiveAccountingStandaloneService } from "@co2-shared/accounting-common";
import {
  CONFIG_DATA_SERVICE_TOKEN,
  ConfigDataStandaloneService,
  IEnvDto,
  PLATFORM_ENV_FILE,
  SELECTED_REGION_SERVICE_TOKEN,
  SelectedRegionStandaloneService,
  TOKEN_SERVICE_TOKEN,
  TokenStandaloneService
} from "@co2-shared/common";
import { MapModule } from "@co2-shared/map";
import "@enersis/gp-components/gp-card";
import "@enersis/gp-components/gp-chart-export-menu";
import "@enersis/gp-components/gp-icon";
import "@enersis/gp-components/gp-kpi";
import "@enersis/gp-components/gp-tabs";
import "@enersis/gp-components/gp-spinner";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, assetUrl("i18n/"), ".json");
}

export function mapEnvironmentConfig() {
  return async () => {
    const dynamicVariables = [{ name: "<%API_BASEURL%>", value: environment.apiBaseUrl }];

    const replaceVariables = (envVariable: string): string => {
      dynamicVariables.forEach(({ name, value }) => (envVariable = envVariable.replace(name, value)));
      return envVariable;
    };

    const mapProps = (environmentConfig: IEnvironment) => {
      Object.keys(environmentConfig).forEach((key) => {
        if (typeof environmentConfig[key] === "object" && !!environmentConfig[key]) {
          mapProps(environmentConfig[key]);
          return;
        }

        if (typeof environmentConfig[key] === "string") {
          environmentConfig[key] = replaceVariables(environmentConfig[key]);
        }
      });
    };
    mapProps(environment);
  };
}

const ENE_MODULES = [
  EneHeaderModule,
  EneIconModule,
  EneSidenavModule,
  EneKpisModule,
  EneModalModule,
  EnePopperModule,
  EneTabsModule,
  EneFilterModule,
  EneFormsModule,
  EneSearchModule,
  EneTooltipModule,
  EneAccordeonModule,
  EneEditModule,
  EneSnackbarModule.forRoot({
    preventDuplicates: true
  }),
  EneKpisV2Module.forRoot(),
  EneChartModule.forRoot(),
  EneUiPipesModule.forRoot()
];

const EFFECTS = [
  AppEffects,
  RouterEffects,
  SimulationEffects,
  BenchmarkEffects,
  TourEffects,
  DistrictEffects,
  Co2SavingEffects,
  EMobilityEffects,
  HeatpumpEffects,
  Co2SavingForAllYearsEffects,
  ConsumptionEffects,
  NetworkEffects,
  ChartCo2EmissionSectorsEffects,
  LocalEnergyMixEffects,
  Co2CockpitEffects,
  ContactEffects,
  ChartBenchmarkingEffects,
  GetAccountingMethodsConfigEffects,
  PutAccountingMethodsConfigEffects,
  GasCockpitKpiEffects,
  GasNetworkEffects,
  MainPolluterEffects,
  MeasuresEffects,
  DatabaseChartEffects,
  SetsEffects
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidenavigationComponent,
    EnergymixSelectComponent,
    ChartEnergyEfficiencyComponent,
    DownloadReportComponent
  ],
  imports: [
    CommonModule,
    BenchmarkingModule,
    DeprecatedMaptopicsModule,
    DeprecatedTimeperiodModule,
    DeprecatedTimelineModule,
    MeasuresModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ContactFormModule,
    IntroductionModalModule,
    MapModule,
    AuthenticationModule.forRoot(environment.loginConfig),
    AppRoutingModule,
    StoreModule.forRoot(reducerToken, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot(EFFECTS),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CdkTableModule,
    MatStepperModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ...ENE_MODULES,
    NguCarouselModule,
    DirectivesModule,
    PipesModule,
    FooterModule,
    HeaderModule,
    DqiTableModule,
    ProgressBarModule,
    SearchboxModule,
    DataPanelModule,
    ReportsPanelModule,
    InfoPanelModule,
    Co2CockpitModule,
    AppDqiModule,
    ReactiveFormsModule,
    CommentModule,
    SimulationModule,
    ContactsModule,
    PopUpModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: TOKEN_SERVICE_TOKEN, useClass: TokenStandaloneService },
    { provide: SELECTED_REGION_SERVICE_TOKEN, useClass: SelectedRegionStandaloneService },
    { provide: ACTIVE_ACCOUNTING_SERVICE_TOKEN, useClass: ActiveAccountingStandaloneService },
    { provide: CONFIG_DATA_SERVICE_TOKEN, useClass: ConfigDataStandaloneService },
    reducerProvider,
    // guards
    AuthGuard,
    EneFormatUnitPipe,
    // services
    EneUiService,
    EneKpisService,
    EneModalService,
    PopupService,
    {
      provide: APP_INITIALIZER,
      useFactory: mapEnvironmentConfig,
      multi: true
    },
    {
      provide: PLATFORM_ENV_FILE,
      useFactory: () => {
        const platformEnvFile: IEnvDto = {
          country: "de",
          co2balance: {
            featureFlags: environment.energyCityFeatureFlags
          }
        };
        return platformEnvFile;
      }
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    }
  ],
  entryComponents: [DownloadReportComponent, ChartEnergyEfficiencyComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
