<div class="contact-form">
  <ul class="contact-form__contact-radio">
    <li class="contact-form__contact-radio__left">
      <input id="radio-error" type="radio" (click)="checkBox(radio[0])" [checked]="radio[0]" />
      <label for="radio-error" class="ene-radio-button">{{ "FOOTER.KONTAKT.REPORT_ERROR" | translate }}</label>
    </li>
    <li class="contact-form__contact-radio__right">
      <input id="radio-feedback" type="radio" (click)="checkBox(radio[1])" [checked]="radio[1]" />
      <label for="radio-feedback" class="ene-radio-button">{{ "FOOTER.KONTAKT.OTHER_MATTERS" | translate }}</label>
    </li>
  </ul>

  <section class="contact-form__input">
    <label class="contact-form__input__label">{{ "FOOTER.KONTAKT.NAME" | translate }}</label>
    <input class="ene-surface-input-main contact-form__input__contact-input" [(ngModel)]="contactObject.name" />
  </section>

  <section class="contact-form__input">
    <label class="contact-form__input__label">{{ "FOOTER.KONTAKT.YOUR_E-MAIL" | translate }} <sup>*</sup></label>
    <input
      class="ene-surface-input-main contact-form__input__contact-input"
      [(ngModel)]="contactObject.email"
      [ngClass]="{ missing: emailMissing === true }"
      (focusout)="stillMissing('email')"
    />
  </section>

  <section class="contact-form__input">
    <label class="contact-form__input__label">{{ "FOOTER.KONTAKT.CALLBACK_PHONE" | translate }}</label>
    <input class="ene-surface-input-main contact-form__input__contact-input" [(ngModel)]="phone" />
  </section>

  <section class="contact-form__input">
    <label class="contact-form__input__label">{{ "FOOTER.KONTAKT.YOUR_MESSAGE" | translate }} <sup>*</sup></label>
    <textarea
      class="ene-surface-input-main contact-form__input__contact-textarea"
      [(ngModel)]="message"
      [ngClass]="{ missing: messageMissing === true }"
      (focusout)="stillMissing('message')"
    ></textarea>
  </section>

  <p class="contact-form__needed-fields">{{ "FOOTER.KONTAKT.REQUIRED_FIELDS" | translate }}</p>

  <button class="contact-form__contact-send" (click)="send()" [ngClass]="{ hotfix: formNotRestricted === false }">
    <h2>{{ "FOOTER.KONTAKT.SEND" | translate }}</h2>
  </button>

  <!-- <hr *ngIf="formNotRestricted"/>
  <section *ngIf="formNotRestricted"
    class="contact-form__contact-footer">
    <ene-icon class="contact-form__contact-footer__article__icon"
      icon="ec_phone"></ene-icon>
    <p><b>{{ "FOOTER.KONTAKT.PHONE_SUPPORT" | translate }}</b></p>
    <p>{{ "FOOTER.KONTAKT.MONDAY_TO_FRIDAY" | translate }}</p>
    <p>{{ "FOOTER.KONTAKT.HOTLINE" | translate }}: {{ supportPhoneNumber }}</p>
  </section> -->
</div>

<section class="status" *ngIf="status">
  <article class="status__box" [ngStyle]="{ backgroundColor: backgroundColor }">
    <span class="loading ene-spinner status__box__loader" *ngIf="spinner">
      <div></div>
      <div></div>
      <div></div>
    </span>
    <div class="status__box__message">{{ text | translate }}</div>
    <div class="status__box__message">
      <b>{{ boldText | translate }}</b>
    </div>
  </article>
</section>
