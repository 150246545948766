import {
  Component,
  OnInit,
  OnDestroy,
  Injector,
  Output,
  EventEmitter,
  ComponentRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { ofType } from "@ngrx/effects";
import { Observable, Subject, zip } from "rxjs";
import { takeUntil, first, map } from "rxjs/operators";
import { FooterService } from "./footer.service";
import * as fromRoot from "@energy-city/components";
import { AuthActionType, AppActionType, EntryComponents } from "@energy-city/components";
import { FooterConfigs, IFooterConfigs } from "./models";
import { DYNAMIC_FOOTER_DATA } from "./models";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {
  public component: ComponentRef<any>;
  public cmpInjector: Injector;
  private destroy$ = new Subject();

  @Output() public onclick: EventEmitter<any> = new EventEmitter();

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private injector: Injector,
    private footerService: FooterService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    const auth$ = this.authSelection;
    const app$ = this.appSelection;

    zip(auth$, app$)
      .pipe(
        first(),
        map(([auth, app]) => [auth.payload, app.payload]),
        takeUntil(this.destroy$)
      )
      .subscribe(([auth, app]: Array<any>) => {
        const name = app.theme.name;
        const data = FooterConfigs.getFormattedData({ auth, app });

        this.loadComponent(name, data);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private loadComponent(name: string, data: IFooterConfigs): void {
    this.component = EntryComponents.footers[name] || EntryComponents.footers.default;
    this.cmpInjector = Injector.create({
      providers: [{ provide: DYNAMIC_FOOTER_DATA, useValue: data }],
      parent: this.injector
    });

    this.cdr.markForCheck();

    this.footerService.onclick$.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onclick.emit(value);
    });
  }

  private get authSelection(): Observable<any> {
    return this.store.pipe(select(fromRoot.GetAuthState), ofType(AuthActionType.LOGIN_SUCCESS));
  }

  private get appSelection(): Observable<any> {
    return this.store.pipe(select(fromRoot.GetAppState), ofType(AppActionType.GET_CLIENT_CONFIG_SUCCESS));
  }
}
