<div class="ene-surface-box-raised header">
  <img src="/assets/images/icons/grants_hand.svg" />
  <div class="ene-surface-box-feature noborder">
    <p>{{ "CONTROL.TABS.GRANTS.HEADING" | translate }}</p>
  </div>
</div>
<ene-tabs mode="horizontal block" [disabled]="true">
  <ene-tab *ngFor="let tab of data[1].tabs" [tabTitle]="tab.name | translate" [icon]="tab.icon"></ene-tab>
</ene-tabs>
<div class="ene-surface-box-feature single test">
  <img src="/assets/images/logos/Logo_Energiefranken.svg" />
  <p [innerHTML]="'CONTROL.TABS.GRANTS.BLOCK1_TEXT' | translate"></p>
  <button
    class="ene-button-pill-primary"
    (click)="navigateTo('https://www.energie-experten.ch/de/energiefranken.html')"
  >
    {{ "CONTROL.TABS.GRANTS.BLOCK1_BUTTON1" | translate }}
  </button>
</div>
