import { Component, OnInit } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import {
  AuthActionType,
  AppActionType,
  SendEmail,
  ContactActionType,
  ContactType
} from "@energy-city/components";
import { Store } from "@ngrx/store";
import * as fromRoot from "@energy-city/components";
import { emailRegex } from "./email.regex";
import { TranslateService } from "@ngx-translate/core";

enum Colors {
  PENDING = "#9B9B9B",
  FAILED = "#D0021B",
  SUCCESS = "#86BF4C"
}

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"]
})
export class ContactFormComponent implements OnInit {
  public authSubscription: Subscription;
  public appSubscription: Subscription;
  public auth$: Observable<any>;
  public app$: Observable<any>;
  public contactSubscription: Subscription;
  public contact$: Observable<any>;
  public authState: any;

  public test: string = "hallo";

  public supportPhoneNumber: string = "";
  public radio: Array<boolean> = [true, false];
  public contactObject: any = {
    name: "",
    email: ""
  };
  public phone: string = "";
  public message: string = "";

  public formNotRestricted: boolean = true;
  public backgroundColor: string = "";
  public status: boolean = false;

  public text: string = "";
  public boldText: string = "";
  public spinner: boolean = false;

  public emailMissing: boolean = false;
  public messageMissing: boolean = false;

  constructor(public store: Store<fromRoot.IApplicationState>, private translate: TranslateService) {}

  public ngOnInit() {
    this.auth$ = this.store.select(fromRoot.GetAuthState);
    this.authSubscription = this.auth$.subscribe((res) => {
      switch (res.type) {
        case AuthActionType.LOGIN_SUCCESS:
          this.authState = res.payload;
          this.contactObject.customer_support_id = this.authState.customer_support_id
            ? this.authState.customer_support_id.toString()
            : "";
          this.contactObject.municipality = this.authState.municipality_name;
          if (this.authState.given_name && this.authState.family_name) {
            this.contactObject.name = this.authState.given_name + " " + this.authState.family_name;
          }
          this.contactObject.email = this.authState.email;
          this.translate
            .get("FOOTER.KONTAKT.PHONENUMBER_" + this.authState.municipality_name.toUpperCase())
            .subscribe((translation) => {
              if (translation.substr(0, 6) === "FOOTER") {
                translation = "n/a";
              }
              this.supportPhoneNumber = translation;
            });
      }
    });

    this.app$ = this.store.select(fromRoot.GetAppState);
    this.appSubscription = this.app$.subscribe((res) => {
      switch (res.type) {
        case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
          if (res.payload.footer[0].type === "contact-restricted") {
            this.formNotRestricted = false;
          }
          break;
      }
    });

    this.contact$ = this.store.select(fromRoot.GetContactState);
    this.contactSubscription = this.contact$.subscribe((res) => {
      switch (res.type) {
        case ContactActionType.SEND_EMAIL_SUCCESS:
          this.setStatus("SUCCESS", "FOOTER.KONTAKT.SUCCESS_BOLD", Colors.SUCCESS, false);
          break;
        case ContactActionType.SEND_EMAIL_ERROR:
          this.setStatus("FAILED", "", Colors.FAILED, false);
          break;
      }
    });
  }

  public checkBox(event: boolean) {
    if (event === false) {
      this.radio[0] = !this.radio[0];
      this.radio[1] = !this.radio[1];
    }
  }

  public send() {
    this.contactObject.email === "" || this.contactObject.email === undefined
      ? (this.emailMissing = true)
      : (this.emailMissing = false);
    this.message === "" ? (this.messageMissing = true) : (this.messageMissing = false);
    if (this.contactObject.email === "" || this.message === "") {
      this.setStatus("FAILED_MISSING_INPUT", "", Colors.FAILED, false);
      return;
    }
    if (this.validEmail(this.contactObject.email) === false) {
      this.setStatus("FAILED_INVALID_EMAIL", "", Colors.FAILED, false);
      this.emailMissing = true;
      return;
    }
    this.store.dispatch(
      new SendEmail(
        this.contactObject.customer_support_id,
        this.contactObject.municipality,
        this.radio.indexOf(true) === 0 ? ContactType.ERROR : ContactType.FEEDBACK,
        document.location.hostname,
        this.contactObject.name,
        this.contactObject.email,
        this.phone,
        this.message,
        this.authState.ags != null
          ? this.authState.ags.toString().length === 8
            ? this.authState.ags.toString()
            : "0" + this.authState.ags.toString()
          : ""
      )
    );
    this.setStatus("PENDING", "", Colors.PENDING, true);
  }

  public setStatus(statusString: string, boldText: string, color: Colors, spinner: boolean) {
    this.formNotRestricted = false;
    this.status = true;
    this.text = `FOOTER.KONTAKT.${statusString}`;
    this.boldText = boldText;
    this.backgroundColor = color;
    this.spinner = spinner;
  }

  public stillMissing(category: string) {
    switch (category) {
      case "email":
        if (
          this.emailMissing === true &&
          this.contactObject.email !== "" &&
          this.validEmail(this.contactObject.email)
        ) {
          this.emailMissing = false;
        }
        break;
      case "message":
        if (this.messageMissing === true && this.message !== "") {
          this.messageMissing = false;
        }
        break;
    }
  }

  public validEmail(email: string): boolean {
    if (email.match(emailRegex) !== null) {
      return true;
    } else {
      return false;
    }
  }
}
