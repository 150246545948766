import { Component, OnInit } from "@angular/core";

// state

// ngrx
import { TranslateService } from "@ngx-translate/core";
@Component({
  /* tslint:disable:component-selector */
  selector: "panel-maptopics",
  templateUrl: "./maptopics.component.html",
  styleUrls: ["./maptopics.component.scss"]
})
export class PanelMaptopicsComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  public ngOnInit() {}
}
