import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { environment } from "@energy-city/environments";
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from "./app/app.module";

import cssVars from "css-vars-ponyfill";

cssVars({
  include: "style,link[rel='stylesheet']:not([href*='//'])",
  onlyLegacy: true,
  watch: true,
  preserveStatic: false,
  silent: true
});

console.log("build_id", environment.image_sha);

if (environment.production) {
  enableProdMode();
}

// don't enable sentry in dev mode
if (environment.production) {
  Sentry.init({
    environment: environment.environment,
    release: environment.image_sha,
    dsn: "https://fcd2a0aa9f2797040f1ae1341fbd62bd@o4505917412343808.ingest.sentry.io/4506230032039937",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation
      }),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
