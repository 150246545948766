import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import * as fromRoot from "@energy-city/components";
import { Store } from "@ngrx/store";
import { DistrictActionType, AppActionType } from "@energy-city/components";
import { IFormatUnitConfig } from "@energy-city/ui/pipes";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "app-info-buergerportal",
  templateUrl: "./info-buergerportal.component.html",
  styleUrls: ["./info-buergerportal.component.scss"]
})
export class InfoBuergerportalComponent implements OnInit {
  protected subscriptionCollection: Array<Subscription> = [];
  public population: string;
  public buildings_count: string;
  public area_m2: string;
  public data: any = {};

  constructor(private store: Store<fromRoot.IApplicationState>, private pipeDecimal: DecimalPipe) {}

  public ngOnInit() {
    this.subscriptionCollection.push(
      this.store.select(fromRoot.GetDistrictState).subscribe((res: any) => {
        switch (res.type) {
          case DistrictActionType.GET_DISTRICT_DATA_SUCCESS:
            const options: IFormatUnitConfig = {
              localization: "de-ch",
              digitsLength: 3,
              container: false,
              lowerUnits: false
            };
            this.population = this.pipeDecimal.transform(res.payload.population, "1.0-0", "de-ch");
            this.buildings_count = this.pipeDecimal.transform(res.payload.buildings_count, "1.0-0", "de-ch");
            this.area_m2 = this.pipeDecimal.transform(res.payload.area_m2 / 1000000, "1.0-1", "de-ch") + " km²";
            break;
        }
      })
    );
    this.subscriptionCollection.push(
      this.store.select(fromRoot.GetAppState).subscribe((res: any) => {
        switch (res.type) {
          case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
            this.data = res.payload.module[0].panels.info.tabs;
        }
      })
    );
  }

  public navigateTo(destination: string) {
    window.open(destination);
  }
}
