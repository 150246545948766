<div class="ene-baselayout-container">
  <app-header tourAnchor="base"></app-header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <app-footer (onclick)="onClick($event)"></app-footer>
</div>
<ene-modal
  id="footer_information"
  [title]="footerModalContent.title | translate"
  [sizing]="footerModalContent.sizing"
  [watermark]="footerModalContent.watermark"
>
  <div class="html-content" [innerHTML]="footerModalContent.content | translate | trustHtml"></div>
</ene-modal>

<ene-modal
  *ngIf="contactModalType === 'new'"
  id="contact"
  [sizing]="'medium'"
  [title]="'FOOTER.KONTAKT.KONTAKT' | translate"
>
  <app-contacts-container></app-contacts-container>
</ene-modal>

<ene-modal
  *ngIf="contactModalType === 'old'"
  id="contact"
  [sizing]="footerModalContent.sizing"
  [title]="footerModalContent.title | translate"
>
  <app-contact-form></app-contact-form>
</ene-modal>
<ene-modal id="introduction" [sizing]="'introduction-modal'" closebutton="false">
  <app-introduction-modal></app-introduction-modal>
</ene-modal>
<app-print-wrapper></app-print-wrapper>
