<aside
  id="sidenavigation"
  ene-sidenav
  [autofill]="true"
  [useRouter]="moduleService.activeRoute"
  #sidenav
  tourAnchor="sidenavigation"
>
  <ene-sidepanel
    #infoPanel
    *ngIf="clientConfig.panels && clientConfig.panels.info"
    name="info"
    [master]="true"
    icon="ec_infopanel"
    [enlarge]="clientConfig.panels.info.enlarge"
    [enlargeSize]="'1500px'"
    [visible]="clientConfig.panels.info.visible"
  >
    <panel-info></panel-info>
  </ene-sidepanel>
  <ene-sidepanel
    #cockpitPanel
    *ngIf="clientConfig.panels && clientConfig.panels.co2_cockpit"
    name="co2_cockpit"
    icon="ec_side_cockpit"
    buttonPosition="start"
    [columns]="{ sm: 7, md: 6, lg: 5.5, xl: 5.5 }"
    [visible]="true"
    [collision]="true"
    [enlarge]="clientConfig.panels.co2_cockpit.enlarge"
    [extendable]="clientConfig.panels.co2_cockpit.extendable"
    [enlargeSize]="'1500px'"
    [stickyheader]="false"
    [progressIndicator]="false"
  >
    <ene-sidenav-content-full>
      <app-co2-cockpit column="full"></app-co2-cockpit>
    </ene-sidenav-content-full>
    <ene-sidenav-content-fixed>
      <app-co2-cockpit *ngIf="cockpitPanel.visible" column="fixed"></app-co2-cockpit>
    </ene-sidenav-content-fixed>
    <ene-sidenav-content-enlarged>
      <app-co2-cockpit *ngIf="cockpitPanel.visible && cockpitPanel.enlarge" column="enlarged"></app-co2-cockpit>
    </ene-sidenav-content-enlarged>
  </ene-sidepanel>
  <ene-sidepanel
    #maptopicsPanel
    name="maptopics"
    tourAnchor="maptopics"
    *ngIf="clientConfig.panels && clientConfig.panels.maptopics"
    icon="ec_side_card"
    buttonPosition="start"
    [columns]="5"
    [visible]="false"
    [collision]="true"
    [enlarge]="clientConfig.panels.maptopics.enlarge"
    [stickyheader]="false"
    [progressIndicator]="false"
  >
    <panel-maptopics></panel-maptopics>
  </ene-sidepanel>
  <ene-sidepanel
    #dataPanel
    name="data"
    icon="ec_header_settings"
    *ngIf="clientConfig.panels?.data"
    [enlargeSize]="'100vw'"
    [enlarge]="true"
    [overlay]="true"
    [visible]="false"
    [collapse]="false"
  >
    <div>
      <h2>{{ "DATA_PANEL.TITLE" | translate }}</h2>
      <app-data-panel></app-data-panel>
    </div>
  </ene-sidepanel>
  <ene-sidepanel
    *ngIf="clientConfig.panels && clientConfig.panels.timeline"
    name="timeline"
    tourAnchor="timeline"
    icon="ec_side_timeline"
    [buttonSize]="0.5"
    buttonPosition="end"
    [progressIndicator]="false"
    [columns]="0.5"
    [visible]="true"
    [collision]="false"
    [collapse]="false"
    [navbuttons]="false"
    align="horizontal"
  >
    <app-timeline></app-timeline>
  </ene-sidepanel>
  <ene-sidepanel
    #measures
    *ngIf="clientConfig.panels?.measures"
    name="measures"
    tourAnchor="measures"
    icon="ec_side_measures"
    buttonPosition="start"
    [columns]="{ sm: 13, md: 13, lg: 11, xl: 11 }"
    [visible]="false"
    [collision]="true"
    [stickyheader]="false"
  >
    <div>
      <h2>{{ "MEASURE.TITLE" | translate }}</h2>
      <div class="infobox">
        <p class="text">{{ "MEASURE.PANEL_DESCRIPTION" | translate }}</p>
      </div>
      <panel-measures></panel-measures>
    </div>
  </ene-sidepanel>
  <ene-sidepanel
    #simulation
    *ngIf="clientConfig.panels?.simulation"
    name="simulation"
    tourAnchor="simulation"
    icon="ec_side_simulation-1"
    buttonPosition="start"
    [width]="'650px'"
    [enlarge]="false"
    [visible]="false"
    [collision]="true"
    [stickyheader]="false"
  >
    <div>
      <h2>
        {{ "SIMULATION.TITLE" | translate }}
      </h2>
      <div class="infobox">
        <p class="text">
          {{ "SIMULATION.PANEL_DESCRIPTION" | translate }}
        </p>
      </div>
      <app-simulation></app-simulation>
    </div>
  </ene-sidepanel>
  <ene-sidepanel
    #benchmarking
    *ngIf="clientConfig.panels?.benchmarking"
    name="benchmarking"
    tourAnchor="benchmarking"
    icon="ec_side_benchmarking"
    buttonPosition="start"
    [columns]="5"
    [visible]="false"
    [collision]="true"
    [width]="'1235px'"
    [stickyheader]="false"
    [disabled]="benchmarkingDisable$ | async"
  >
    <div>
      <h2>{{ "BENCHMARKING.TITLE" | translate }}</h2>
      <div class="infobox">
        <p class="text">
          {{ benchmarkingPanel.activeTab?.text | translate }}
        </p>
      </div>
      <panel-benchmarking #benchmarkingPanel></panel-benchmarking>
    </div>
  </ene-sidepanel>
  <ene-sidepanel
    #reports
    *ngIf="clientConfig.panels?.reports"
    name="reports"
    tourAnchor="reports"
    icon="ec_side_report"
    [enlargeSize]="'100vw'"
    [enlarge]="true"
    [overlay]="true"
    [visible]="false"
    [collapse]="false"
  >
    <div>
      <h2>{{ "REPORTS.PANEL_TITLE" | translate }}</h2>
      <app-reports-panel></app-reports-panel>
    </div>
  </ene-sidepanel>
</aside>
<co2-map #appMap class="co2balance" [panelsWidth]="panelsWidth"></co2-map>
