<div class="ene-surface-box-raised header">
  <img src="/assets/images/logos/logo_regensdorf.png" />
  <div class="ene-surface-box-feature">
    <p>{{ "CONTROL.TABS.WELCOME.HEADING" | translate }}</p>
  </div>
  <table>
    <tr>
      <td>{{ population }}</td>
      <td>{{ "CONTROL.TABS.WELCOME.INHABITANTS" | translate }}</td>
    </tr>
    <tr>
      <td>{{ buildings_count }}</td>
      <td>{{ "CONTROL.TABS.WELCOME.BUILDINGS" | translate }}</td>
    </tr>
    <tr>
      <td>
        <span>{{ area_m2 }}</span>
      </td>
      <td>{{ "CONTROL.TABS.WELCOME.AREA" | translate }}</td>
    </tr>
  </table>
</div>
<ene-tabs mode="horizontal block">
  <ene-tab *ngFor="let tab of data[0].tabs" [tabTitle]="tab.name | translate" [active]="tab.active" [icon]="tab.icon">
    <div class="info-box">
      <div *ngFor="let item of tab.items" class="ene-surface-box-feature {{ item.sizing }}">
        <img *ngFor="let image of item.images" [src]="'/assets/images/logos/' + image" />
        <p [innerHTML]="item.description | translate"></p>
        <button *ngFor="let button of item.buttons" [class]="button.class" (click)="navigateTo(button.destination)">
          {{ button.name | translate }}
        </button>
      </div>
    </div>
  </ene-tab>
</ene-tabs>
