import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ComponentRef
} from "@angular/core";
import { Store, select } from "@ngrx/store";
import { ofType } from "@ngrx/effects";
import { Observable, zip, Subject } from "rxjs";
import { first, takeUntil, map } from "rxjs/operators";
import * as fromRoot from "@energy-city/components";
import {
  AuthActionType,
  LoginSuccess,
  ILoginData,
  AppActionType,
  GetClientConfigSuccess
} from "../../state/index";
import { EntryComponents } from "../../core/class/entryComponents.class";
import { HeaderConfigs, IHeaderConfigs, DYNAMIC_HEADER_DATA } from "./models";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  public component: ComponentRef<any>;
  public cmpInjector: Injector;
  private destroy$ = new Subject();

  constructor(
    private store: Store<fromRoot.IApplicationState>,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    const auth$ = this.authSelection;
    const app$ = this.appSelection;

    zip(auth$, app$)
      .pipe(
        first(),
        map(([auth, app]) => ({ auth: auth.payload, app: app.payload })),
        takeUntil(this.destroy$)
      )
      .subscribe(({ auth, app }: { auth: ILoginData; app: any }) => {
        const name = app.theme.name || "";
        const data = HeaderConfigs.getFormattedData({ auth, app });
        this.loadComponent(name, data);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private loadComponent(name: string, data: IHeaderConfigs): void {
    this.component = EntryComponents.headers[name] || EntryComponents.headers.default;
    this.cmpInjector = Injector.create({
      providers: [{ provide: DYNAMIC_HEADER_DATA, useValue: data }],
      parent: this.injector
    });

    this.cdr.markForCheck();
  }

  private get authSelection(): Observable<LoginSuccess> {
    return this.store.pipe(select(fromRoot.GetAuthState), ofType<LoginSuccess>(AuthActionType.LOGIN_SUCCESS));
  }

  private get appSelection() {
    return this.store.pipe(
      select(fromRoot.GetAppState),
      ofType<GetClientConfigSuccess>(AppActionType.GET_CLIENT_CONFIG_SUCCESS)
    );
  }
}
