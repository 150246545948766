import { Component, ViewChildren, QueryList, ViewContainerRef, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { ModulesService } from "@energy-city/components";
import { Subscription } from "rxjs";
import { InfoPanelEntryComponents } from "../class/entryComponents.class";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "panel-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"]
})
export class PanelInfoComponent implements AfterViewInit {
  @ViewChildren("dynamic", { read: ViewContainerRef }) public ContentTarget: QueryList<ViewContainerRef>;
  protected subscriptionCollection: Array<Subscription> = [];
  public infoPanelData: any = {};
  public infoTabs = [];
  public mainTitle: string;

  public population: string;
  public buildings_count: string;
  public area_m2: string;

  public data: any = {};

  constructor(public moduleService: ModulesService, private cd: ChangeDetectorRef) {
    if (this.moduleService.getActiveModule()) {
      this.infoTabs = this.moduleService.getActiveModule().panels.info.tabs;
      this.mainTitle = this.moduleService.getActiveModule().panels.info.title;
    }
  }

  public ngAfterViewInit() {
    this.moduleService.loadDynamicTabsContent(this.ContentTarget, this.infoTabs, InfoPanelEntryComponents.InfoTabs);
    this.cd.detectChanges();
  }
}
