import { Component, OnInit, OnDestroy } from "@angular/core";
import { filter } from "rxjs/operators";

// ngrx
import { Subscription } from "rxjs";
import { Store, select } from "@ngrx/store";

// states
import * as fromRoot from "@energy-city/components";
import {
  TimelineInit,
  TimelineUpdate,
  ResetActionType,
  AppActionType,
  UtilService,
  IAppState
} from "@energy-city/components";

@Component({
  selector: "app-timeline",
  templateUrl: "./timeline.component.html",
  styleUrls: ["./timeline.component.scss"]
})
export class TimelineComponent implements OnInit, OnDestroy {
  protected subscriptionCollection: Array<Subscription> = [];

  private timlineStartYear = Number(this.utilService.timelineInitialYear);
  private timelineStart = Number(this.utilService.timelineRange[0]);
  private timelineEnd = Number(this.utilService.timelineRange[1]);
  public slideConfig: any = {};
  public isTimelineDisabled: boolean = false;

  public timelineYear: number[] = [this.timlineStartYear];

  constructor(public store: Store<fromRoot.IApplicationState>, private utilService: UtilService) {}

  public ngOnInit() {
    this.store.dispatch(new TimelineInit(this.timlineStartYear));
    this.utilService.setSelectedYear(this.timlineStartYear);
    const self = this;
    this.slideConfig = {
      range: {
        min: this.timelineStart - 1,
        max: this.timelineEnd + 1
      },
      tooltips: true,
      step: 1,
      padding: 1,
      pips: {
        mode: "steps",
        density: 4,
        filter: function (value, type) {
          if (self.timelineStart - 1 === value || self.timelineEnd + 1 === value) {
            return 0;
          }

          let result;

          if (self.timelineEnd - self.timelineStart <= 50) {
            result = value % 5 === 0 ? 1 : 0;
          } else {
            result = value % 10 === 0 ? 1 : 0;
          }
          return result;
        }
      }
    };

    this.subscriptionCollection.push(
      this.store
        .pipe(
          select(fromRoot.GetResetState),
          filter((res) => res.type === ResetActionType.RESET_UPDATE && res.payload.id.timeline != null)
        )
        .subscribe((res) => {
          // set year in timeline
          switch (res.payload.id.timeline.year) {
            case "start":
              this.timelineYear = [this.timelineStart];
              this.onSliderChange(this.timelineStart);
              break;
            case "end":
              this.timelineYear = [this.timelineEnd];
              this.onSliderChange(this.timelineEnd);
              break;
            default:
              if (Number.isSafeInteger(res.payload.id.timeline.year)) {
                this.timelineYear = [res.payload.id.timeline.year];
                this.onSliderChange(res.payload.id.timeline.year);
              } else {
                console.warn("provided timelinevalue for reset is not applicable");
              }
              break;
          }
        })
    );

    this.subscriptionCollection.push(
      this.store
        .pipe(
          select(fromRoot.GetAppState),
          filter((res: IAppState) => res.type === AppActionType.GET_CLIENT_CONFIG_SUCCESS)
        )
        .subscribe((res) => {
          const temp = res.payload.module[0].panels.timeline.disabled;
          temp !== undefined ? (this.isTimelineDisabled = temp) : (this.isTimelineDisabled = false);
        })
    );
  }

  public onSliderChange($event: any) {
    this.store.dispatch(new TimelineUpdate($event));
  }

  public ngOnDestroy() {
    this.subscriptionCollection.forEach((subscription) => {
      try {
        subscription.unsubscribe();
      } catch (error) {
        // just ignore it
      }
    });
  }
}
