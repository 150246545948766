import { DOCUMENT } from "@angular/common";
import { Component, Inject, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as fromRoot from "@energy-city/components";
import { EneModalService } from "@energy-city/ui/modal";
import { Store, select } from "@ngrx/store";

// services
import {
  AppActionType,
  AuthActionType,
  AuthenticationService,
  BasicAppComponent,
  ContactType,
  CookieSettingsService,
  EnvironmentType,
  GetClientConfig,
  ILanguage,
  ModulesService,
  PopupService,
  RegionService,
  SetCurrentContactComponent,
  UtilService,
  assetUrl,
  environment,
  injectCssLinkToDocumentRoot
} from "@energy-city/components";
import { TranslateService } from "@ngx-translate/core";
import { PopperContentComponent } from "libs/ui/src/lib/popper";
import { get } from "lodash";

declare var require: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends BasicAppComponent {
  @ViewChild("languageSwitcher", { static: false }) protected languageSwitcherRef: PopperContentComponent;

  public footerModalContent: any = {};
  public environment = environment;
  public pagetitle: string = "";

  constructor(
    protected router: Router,
    protected store: Store<fromRoot.IApplicationState>,
    protected titleService: Title,
    protected moduleService: ModulesService,
    protected eneModalService: EneModalService,
    @Inject(DOCUMENT) protected _document: HTMLDocument,
    protected authenticationService: AuthenticationService,
    protected utilService: UtilService,
    protected regionService: RegionService,
    protected translate: TranslateService,
    protected popupService: PopupService,
    protected cookieSettingsService: CookieSettingsService
  ) {
    super();

    this.auth$ = this.store.pipe(select(fromRoot.GetAuthState));
    this.authSubscription = this.auth$.subscribe((res) => {
      switch (res.type) {
        case AuthActionType.LOGIN_SUCCESS:
          this.authState = res.payload;
          this.store.dispatch(new GetClientConfig({}));
          this.setPageTitle(this.authState.municipality_name);

          // Activate Google Analytics only when in Production environment
          if (this.authenticationService.tokenParsed.gaProperty) {
            (window as any).activateGoogleAnalytics(this.authenticationService.tokenParsed.gaProperty);
          } else {
            console.error("Couldn't activate Analytics. Can't find gaProperty");
          }
          if (
            !this.cookieSettingsService.readCookieSettings()?.functional ||
            !this.cookieSettingsService.readCookieSettings()?.dsgvo
          ) {
            popupService.openDataPrivacy();
          }
          break;
      }
    });
    this.app$ = this.store.select(fromRoot.GetAppState);
    this.appSubscription = this.app$.subscribe((res) => {
      switch (res.type) {
        case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
          const clientConfig = res.payload;
          this.contactModalType = clientConfig.useNewContactForm ? "new" : "old";
          this.prepareTheme(clientConfig.theme);
          this.prepareEnvironments(clientConfig.environments);
          this.moduleService.setClientConfig(clientConfig);
          this.utilService.clientConfig = clientConfig;
          this.utilService.displayDataPrivacyMessage =
            get(clientConfig, "module[0].panels.buildings.displayDataPrivacyMessage") ||
            get(clientConfig, "module[0].panels.selection.displayDataPrivacyMessage") ||
            false;
          const firstroute = this.moduleService.modules[0];
          this.router.navigate([firstroute + "/cockpit"]).then(() => {
            if (clientConfig.introductionModal) {
              this.openLaunchpad();
            }
          });
          break;
      }
    });
  }

  /**
   * Sets the language, will be called once in ngInit and throught the dropdown in the frontend
   * @param language object of ILanguage {name:, code:"de-ch"}
   */
  public switchLanguage(language: ILanguage) {
    // HOTFIX TODO CHECK IF USER CLICKED SAME LANGUAGE, DO NOT RELOAD FROM SERVER
    // if (language.code !== this.activeLanguage.code) {
    this.utilService.setLocale(language.code);
    // this.store.dispatch(new UpdateTranslations({
    //   language: language.code
    // }));
    // }
    if (this.languageSwitcherRef) {
      this.languageSwitcherRef.hide(); // force closing of Tooltip
    }
  }

  /**
   * executed on (click) action for footer nodal
   *
   * TODO: change footer configs, move into footer component
   */
  public onClick(event: any) {
    if (event.hasOwnProperty("type") && event.type.indexOf("contact") >= 0) {
      this.footerModalContent.content = undefined;
      this.footerModalContent.title = event.name;
      this.footerModalContent.sizing = event.sizing;
      this.store.dispatch(new SetCurrentContactComponent(ContactType.CONTACTS));
      this.eneModalService.open("contact");
      return;
    } else if (typeof event.link === "boolean" && event.link) {
      this.footerModalContent.content = event.content;
      this.footerModalContent.title = event.name;
      if (event.content_title) {
        this.footerModalContent.title = event.content_title;
      } else {
        this.footerModalContent.title = event.name;
      }
      this.footerModalContent.sizing = event.sizing;
      this.footerModalContent.watermark = event.watermark;
      this.eneModalService.open(event.type);
    }
  }

  protected setPageTitle(prefix: string) {
    this.titleService.setTitle(prefix + " | " + this.pagetitle);
  }

  protected prepareEnvironments(environments) {
    if (!environments) {
      return;
    }
    this.environment.detailInformationClasses = environments.detailInformationClasses;
    this.environment.dataLayerClasses = environments.dataLayerClasses;
    this.environment.selected_year = environments.selected_year;
    this.environment.timeperiodPossibleYears = environments.timeperiodPossibleYears;

    this.utilService.setSelectedYear(this.environment.selected_year);
    this.utilService.selectedScenario = environments.selected_scenario;
  }

  public reloadPage() {
    // function is called by (click) on header logo in app.component.html
    window.location.href = "/";
  }

  protected openIntroductionModal() {
    const showIntroductionModal = localStorage.getItem("showIntroductionModal");
    const showModal: boolean = showIntroductionModal === "true" || showIntroductionModal === null;
    if (showModal) {
      this.eneModalService.open("introduction");
    }
  }

  public prepareTheme(theme: any) {
    // set favicon and pagetitle
    this.pagetitle = theme.pagetitle;
    this.setPageTitle(this.authState.municipality_name);
    this._document
      .getElementById("appFavicon")
      .setAttribute("href", "/assets/images/favicons/" + this.environment.favicon);
    this._document
      .getElementById("appleTouchIcon")
      .setAttribute("href", "/assets/images/logos/icon_" + theme.name + ".png");
    this._document
      .getElementById("msAppImage")
      .setAttribute("content", "/assets/images/logos/icon_" + theme.name + ".png");
    // set theme
    require("style-loader!libs/components/src/lib/styles/theme-" + theme.name + ".scss");
  }

  public ngOnInit(): void {
    injectCssLinkToDocumentRoot(assetUrl("/fonts/MaterialIcons.css"), this._document);
  }

  protected openLaunchpad() {
    // load introduction modal
    if (
      this.environment.environment === EnvironmentType.DEVELOPMENT ||
      this.environment.environment === EnvironmentType.RELEASE_CANDIDATE
    ) {
      this.router.navigate([{ outlets: { slot: "launchpad" } }]);
    } else {
      this.openIntroductionModal();
    }
  }

  public ngOnDestroy() {
    // tslint:disable-next-line:no-unused-expression
    this.appSubscription ? this.appSubscription.unsubscribe() : null;
    // tslint:disable-next-line:no-unused-expression
    this.authSubscription ? this.authSubscription.unsubscribe() : null;
  }
}
