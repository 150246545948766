import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ContactFormComponent } from "./contact-form.component";

@NgModule({
  declarations: [ContactFormComponent],
  imports: [TranslateModule, FormsModule, CommonModule],
  exports: [ContactFormComponent]
})
export class ContactFormModule {}
