/* tslint:disable:member-ordering */

import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, mergeMap, catchError, concatMap } from "rxjs/operators";
import { of } from "rxjs";
import { DataService } from "../../services/data.service";
import { ContactActionType, SendEmailSuccess, SendEmail, SendEmailError } from "./contact-actions";
import { ErrorAction } from "./../common";

@Injectable()
export class ContactEffects {
  @Effect() public sendEmail$ = this.actions$.pipe(
    ofType(ContactActionType.SEND_EMAIL),
    mergeMap((action: SendEmail) =>
      this.dataService
        .sendEmailToEdgeService(
          {
            userName: action.contactName,
            userEmail: action.email,
            userPhone: action.phone,
            customerSupportId: action.customerSupportId,
            municipality: action.municipality,
            url: action.url,
            type: action.contactType,
            text: action.message,
            ags: action.ags.toString()
          },
          action.contactType
        )
        .pipe(
          map((data) => new SendEmailSuccess(data)),
          catchError((error) => of(new SendEmailError(error)))
        )
    )
  );

  constructor(private dataService: DataService, private actions$: Actions) {}
}
