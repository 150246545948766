import { InfoSectorsComponent } from "../info/info-sectors/info-sectors.component";
import { InfoBuergerportalComponent } from "../info/info-buergerportal/info-buergerportal.component";
import { InfoBuergerportalGrantsComponent } from "../info/info-buergerportal/info-buergerportal-grants/info-buergerportal-grants.component";
import { NewsComponent } from "../info/news/news.component";

export class InfoPanelEntryComponents {
  public static InfoTabs = {
    InfoSectorsComponent: InfoSectorsComponent,
    InfoBuergerportalComponent: InfoBuergerportalComponent,
    InfoBuergerportalGrantsComponent: InfoBuergerportalGrantsComponent,
    NewsComponent: NewsComponent
  };
}
