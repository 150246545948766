import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimeperiodComponent } from "./timeperiod.component";
import { TranslateModule } from "@ngx-translate/core";
import { DeprecatedEneRangeSliderModule } from "libs/ui/src/lib/forms";
import { EnePopperModule } from "libs/ui/src/lib/popper";

@NgModule({
  declarations: [TimeperiodComponent],
  imports: [CommonModule, TranslateModule, DeprecatedEneRangeSliderModule, EnePopperModule],
  exports: [TimeperiodComponent]
})
export class DeprecatedTimeperiodModule {}
