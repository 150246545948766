import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IInitialConfig } from "./core/global.interface";
import { AuthenticationService } from "./services/exported/authentication.service";
import { HttpClientModule } from "@angular/common/http";
import { INITIAL_CONFIG } from "./core/tokens";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: []
})
export class AuthenticationModule {
  public static forRoot(initialConfig: IInitialConfig): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: INITIAL_CONFIG,
          useValue: initialConfig
        },
        AuthenticationService
      ]
    };
  }
}
