/* tslint:disable:member-ordering */

import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { map, mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ContactNewActionType, SendEmailNewSuccess, SendEmailNew, SendEmailNewError } from "./contact.actions";
import { DataService } from "../../services/data.service";

@Injectable()
export class ContactNewEffects {
  @Effect() public sendEmail$ = this.actions$.pipe(
    ofType(ContactNewActionType.SEND_EMAIL),
    mergeMap((action: SendEmailNew) =>
      this.dataService
        .sendEmailToEdgeService(
          {
            ...action.payload,
            type: action.contactType
          },
          action.contactType
        )
        .pipe(
          map((data) => new SendEmailNewSuccess(data)),
          catchError((error) => of(new SendEmailNewError(error)))
        )
    )
  );

  constructor(private dataService: DataService, private actions$: Actions) {}
}
