import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PanelMaptopicsComponent } from "./maptopics.component";
import { TranslateModule } from "@ngx-translate/core";
import { MapModule } from "@co2-shared/map";
import { PipesModule } from "../../../core/pipe/pipes.module";

@NgModule({
  declarations: [PanelMaptopicsComponent],
  imports: [CommonModule, TranslateModule.forRoot(), MapModule, PipesModule],
  exports: [PanelMaptopicsComponent]
})
export class DeprecatedMaptopicsModule {}
