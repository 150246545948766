import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { Go, AuthenticationService } from "@energy-city/components";
import * as fromRoot from "@energy-city/components";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public store: Store<fromRoot.IApplicationState>, private authService: AuthenticationService) {}

  public canActivate(): Observable<boolean> | boolean {
    try {
      if (this.authService.loggedIn) {
        return true;
      } else {
        this.store.dispatch(
          new Go({
            path: ["/login"]
          })
        );
      }
    } catch (e) {
      this.store.dispatch(
        new Go({
          path: ["/login"]
        })
      );
    }
  }
}
