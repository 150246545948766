import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import * as fromRoot from "@energy-city/components";
import { Store } from "@ngrx/store";
import { AppActionType } from "@energy-city/components";

@Component({
  selector: "app-info-buergerportal-grants",
  templateUrl: "./info-buergerportal-grants.component.html",
  styleUrls: ["./../info-buergerportal.component.scss"]
})
export class InfoBuergerportalGrantsComponent implements OnInit {
  protected subscriptionCollection: Array<Subscription> = [];
  public data: any = {};
  constructor(private store: Store<fromRoot.IApplicationState>) {}

  public ngOnInit() {
    this.subscriptionCollection.push(
      this.store.select(fromRoot.GetAppState).subscribe((res: any) => {
        switch (res.type) {
          case AppActionType.GET_CLIENT_CONFIG_SUCCESS:
            this.data = res.payload.module[0].panels.info.tabs;
        }
      })
    );
  }

  public navigateTo(destination: string) {
    window.open(destination);
  }
}
