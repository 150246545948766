import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { IApplicationState, LoginSuccess, ILoginData } from "../../state/index";
import { AuthenticationService } from "../../modules/authentication/services/exported/authentication.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { IConnect } from "../../modules/authentication/core/global.interface";

@Component({
  selector: "app-login-wrapper",
  templateUrl: "./login-wrapper.component.html",
  styleUrls: ["./login-wrapper.component.scss"]
})
export class LoginWrapperComponent implements OnInit, OnDestroy {
  private authServiceConnection: IConnect;
  private destroy$ = new Subject();

  constructor(private authenticationService: AuthenticationService, public store: Store<IApplicationState>) {
    this.authServiceConnection = this.authenticationService.connect();
  }

  public ngOnInit() {
    this.authServiceConnection
      .login()
      .pipe(takeUntil(this.destroy$))
      .subscribe((parsedToken) => {
        const tokenSelected: ILoginData = {
          user: parsedToken.preferred_username,
          language: parsedToken.language,
          logo_main: parsedToken.logo?.main || "Klimanavi-Logo.svg",
          logo_footer: parsedToken.logo?.footer || "blank.gif",
          family_name: parsedToken.family_name,
          given_name: parsedToken.given_name,
          email: parsedToken.email,
          // For some realms parsedToken.municipality_name is an Array (containing all groups the user is in)
          // For NetzeBW realm this is just a string
          municipality_name: Array.isArray(parsedToken.municipality_name)
            ? parsedToken.municipality_name[0]
            : parsedToken.municipality_name,
          customer_support_id: parsedToken.customerSupportId,
          tenant: parsedToken.tenant,
          level: parsedToken.mainRegion.level
        };
        this.store.dispatch(new LoginSuccess(tokenSelected));
      });
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
